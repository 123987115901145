<template>
    <div class="headerLegacy text-center bodyBG">
        <p>{{$t('login.zulu')}} <router-link :to="{name : 'introducingZuluTrade'}">{{$t('login.here')}}</router-link>. {{$t('login.existing')}} <a :href="static_vars.OldWebsiteURL" target="_blank">{{$t('login.here')}}</a></p>
    </div>
    <div class="userSteps_header border-bottom d-flex align-items-center align-items-center justify-content-between">
        <span v-if="!Object.keys(store.flavordata).length || !store.flavordata.whitelabelLogo">
            <router-link to="/" class="logo d-block lightTheme"><img width="180" src="/assets/images/logo.svg" :alt="$t('head.text21')" :title="$t('head.text22')"  /></router-link>
              <router-link to="/" class="logo darkTheme d-none"><img width="180"  src="/assets/images/whiteLogo.svg"  :alt="$t('head.text21')" :title="$t('head.text22')"  /></router-link>
        </span>
        <span v-else> 
            <router-link to="/" class="logo d-block lightTheme" v-if="Object.keys(store.flavordata).length && store.flavordata.whitelabelLogo"><img width="180" :src="`https://www.zulutrade.com/${store.flavordata.whitelabelLogo}`" :alt="$t('head.text21')" :title="$t('head.text22')"  /></router-link>
              <router-link to="/" class="logo darkTheme d-none" v-if="Object.keys(store.flavordata).length && store.flavordata.whitelabelLogo"><img width="180" :src="`https://www.zulutrade.com/${store.flavordata.whitelabelLogo}`" :alt="$t('head.text21')" :title="$t('head.text22')"  /></router-link>
        </span>
        <div class="rightBTN d-flex align-items-center justify-content-end position-relative">
          <a href="javascript:void(0)" class="d-flex align-items-center me-md-4 me-2 medium linkBtn" @click.prevent="openMenu = !openMenu">
            <span class="maskedIcon"></span> <span class="mobHide">{{$t('login.mobile_app')}}</span></a>
              <div class="dropdown_menu_animated maxAuto dropdown-menu p-3 text-center" :class="openMenu?'show':''">
                    <p class="mb-0 f-14 line-4">{{$t('login.scan_qr')}}</p>
                    <ul class="mb-0 d-flex align-items-center">
                        <v-lazy-image width="140" class="mx-auto cardborder" src="/assets/images/zuluScan1.svg" :alt="$t('login.text2')" :title="$t('login.text3')" />
                    </ul>
                    <ul class="mb-0 d-flex align-items-center justify-content-center">
                        <li>
                            <a href="https://apps.apple.com/gr/app/zulutrade/id336913058" target="_blank" class="fadebg appStore text-white medium f-14 me-2">
                                <v-lazy-image src="/assets/images/iosIcon.png" :alt="$t('login.text4')" :title="$t('login.text5')" />
                                <span>{{$t('login.ios')}}</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=zulu.trade.app" target="_blank" class="fadebg appStore text-white medium f-14">
                                <v-lazy-image src="/assets/images/androidIcon.png" :alt="$t('login.text6')" :title="$t('login.text7')" />
                                <span>{{$t('login.android')}}</span>
                            </a>
                        </li>
                    </ul>
              </div>
              <a id="theme-toggle" class="d-flex" :title="$t('login.text9')" :class="[{'light':currentTheme=='light'},{'dark':currentTheme=='dark'}]" href="javascript:void(0)" @click="changeTheme()">
                    <vue-feather class="me-1" size="20" type="moon"  v-if="currentTheme=='light'"></vue-feather> 
                    <vue-feather class="me-1" size="20" type="sun" v-if="currentTheme=='dark'"></vue-feather> 
                </a>
        </div>
    </div>
    <section class="blockElement ContentBlock">
        <div class="container">
            <div class="row justify-content-center vhCalc align-items-center">
                <div class="col-12 col-md-8 col-xl-5">
                    <div class="bg-white boxed px-md-5 py-md-4">
                        <h3 class="text-center mb-md-4">{{$t('login.log_in')}}</h3>
                        <div class="d-flex justify-content-center googleSignInButton">
                                <GoogleSignInButton
                                    @success="handleLoginSuccess"
                                    @error="handleLoginError"
                                    size = "medium"
                                ></GoogleSignInButton>
                            </div>
                            <div class="text-center">
                                <p class="or medium text-center my-4">{{$t('login.or')}}</p>
                            </div>
                        <Form class="login-content" @submit="submit">
                            <div class="form-group">
                                <label>{{$t('login.address')}}</label>
                                <Field
                                    class="form-control"
                                    name="username"
                                    autofill="off"
                                    autocomplete="username"
                                    v-model="form.username"
                                    :placeholder="$t('login.email_placeholder')"
                                    rules="required"
                                    />
                                <ErrorMessage class="text-danger" name="username" />
                            </div>
                            <div class="form-group">
                                <label>{{$t('login.password')}}</label>
                                <div class="position-relative">
                                    <Field
                                :type="inputtype"
                                class="form-control"
                                autofill="off"
                                name="password"
                                v-model="form.password"
                                rules="required|min:2"
                                :placeholder="$t('login.password_placeholder')"
                                autocomplete="new-password"
                                />
                                <vue-feather class="position-absolute eye pointer searchIcon searchIcon-auto d-flex" :type="iconType" size="23" @click="switchVisibility"></vue-feather>
                                </div>
                                
                                
                                <ErrorMessage class="text-danger" name="password" />
                                
                                <div class="forgotPassord mt-1">
                                    <a :href="static_vars.domainURL+'register/forgot-password'" class="link f-13">{{$t('login.forgot')}}</a>
                                </div>
                            </div>
                            <div class="form-group">
                                <Field type="hidden" class="form-control" name="Captcha" rules="required" v-if="!captchaToken"/>
                                  <vue-recaptcha :sitekey="`${static_vars.v2recaptchaKey}`"
                                      size="normal" 
                                      theme="light"
                                      hl="en"
                                      @verify="recaptchaVerified"
                                      @expire="recaptchaExpired"
                                      @fail="recaptchaFailed"
                                      @error="recaptchaError"
                                      ref="vueRecaptcha"/>
                                  <ErrorMessage name="Captcha" class="text-danger" />
                            </div>
                            <div class="form-group">
                                <button type="submit" class="button fillBtn large w-100" href="javascript:void(0)">{{$t('login.button')}}</button>
                            </div>
                            <!--  <div class="text-center form-group">
                                <p class="or f-16">or Sign in Using</p>
                            </div>
                            <div class="text-center form-group">
                                <a class="borderButton" href="javascript:void(0)">
                                    <v-lazy-image width="70" height="20" src="../../../public/assets/images/google-logo.svg" :alt="Logo" :title="Login With Google" />
                                </a>
                            </div> -->
                            
                            <div class="text-center form-group">
                                <p class="mb-0">{{$t('login.member')}} <a :href="static_vars.domainURL+'register'" class="link f-13">{{$t('login.sign_up')}}</a></p>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="footerSteps d-flex align-items-center">
        <div class="logoFooter d-flex align-items-center">
            <v-lazy-image class="lightTheme" src="/assets/images/footerLogo.png" loading="lazy" width="90" height="18" :alt="$t('login.text8')" :title="$t('login.copy')" />
            <v-lazy-image class="darkTheme d-none" src="/assets/images/white-logo.svg" loading="lazy" width="90" height="18" :alt="$t('login.text8')" :title="$t('login.copy')" />
            <p class="mb-0 ms-2">{{$t('login.year')}}</p>
        </div>
        <ul class="footerLinks d-flex align-items-center justify-content-center mb-0">
            <!-- <li v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 38"><a href="/assets/pdf/legal/TOS-EU.pdf" class="ps-0" target="_blank">Terms of service</a> · </li>
            <li v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 1"><a href="/assets/pdf/legal/TOS-EU.pdf" class="ps-0" target="_blank">Terms of service</a> · </li> -->
            <li><router-link to="/terms-of-service" target="_blank">{{$t('login.terms')}}</router-link>· </li>
            <li><router-link to="/privacy-policy" target="_blank">{{$t('login.privacy')}}</router-link>· </li>
            <li><router-link to="/risk-disclaimer" target="_blank">{{$t('login.risk')}}</router-link></li>
        </ul>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import PriceFeed from '@/store/stomp';
    import moment from "moment"
    // import { load } from 'recaptcha-v3'
    import $ from 'jquery'
    import vueRecaptcha from 'vue3-recaptcha2';

    export default {
        setup() {
            const store = myStore();
            const Crypto = require("crypto-js");
            return { store,Crypto };//Crypto
        },
        data(){
            return {
                form : {
                  username : '',
                  password : ''
              },
              currentTheme : '', 
              showpassword: false,
              openMenu: false,
              iconType: "eye-off",
              inputtype: "password",
              captchaToken : ''
             };
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            vueRecaptcha
          },
        methods:{
            recaptchaVerified(response) {
            this.captchaToken = response
            },
            recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
            this.captchaToken = ''
            },
            recaptchaFailed() {
            this.captchaToken = ''
            },
            recaptchaError() {
            this.captchaToken = ''
            },
            handleLoginSuccess(response){
                if(response.credential){
                    let formData = {};
                    formData['tokenType'] = 'GOOGLE'
                    formData['grant_type'] = 'password';
                    formData['token'] = response.credential;
                    // if(this.captchaToken){
                    //     formData['captcha'] = {};
                    //     formData['captcha']['response'] = this.captchaToken
                    // }
                    this.store.register(formData,true,this)
                }
            },
            CretioFunc(){
                try{
                    // window.gtag('event', 'email_verify');
                  if(Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode){
                        for(var key in this.static_vars.cretioCountryJson){
                            if(key == this.store.flavordata.userCountry.isoCode){
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                 var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window .criteo_q.push(
                                 { event: "setaccount", account: this.static_vars.cretioCountryJson[key]}, // You should never update this line
                                 { event: "setSiteType", type: deviceType},
                                 { event: "setemail", email: this.Crypto.SHA256('1').toString() , hash_method: "sha256" },
                                { event: "viewBasket", user_segment : 1, item: [
                                   {id : (Math.random()*10000).toString(), price:1, quality : 1}
                                ]}
                                );
                            }
                        }
                    }
                }catch(e){
                  console.log('cretio ex',e)
                }
            },
            CretioGoogleFunc(){
                try{
                    window.gtag('event', 'email_verify');
                    window.fbq('trackCustom', 'email_verify', {'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name},{'eventID' : `${this.store.customerDetail?.id}_email_verify`});
                    if(Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode){
                        for(var key in this.static_vars.cretioCountryJson){
                            if(key == this.store.flavordata.userCountry.isoCode){
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                 var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window .criteo_q.push(
                                 { event: "setaccount", account: this.static_vars.cretioCountryJson[key]}, // You should never update this line
                                 { event: "setSiteType", type: deviceType},
                                 { event: "setemail", email: this.Crypto.SHA256('1').toString() , hash_method: "sha256" }, 
                                  //{ event: "setEmail", email: "1"  },
                                    { event: "viewBasket", user_segment : 1, item: [
                                         {id : (Math.random()*10000).toString(), price:1, quality : 1}
                                    ]},
                                    { event: "trackTransaction", id: (Math.random() * 50000), item: [
                                        {id :  Math.ceil(Math.random()*10300 * Math.random() * 50000), price:1, quality : 1}
                                    ]}
                                );
                            }
                        }
                    }
                }catch(e){
                  console.log('cretio ex',e)
                }
            },
            handleLoginError(){
                console.error("Login failed");
            },
            loginWithGoogle(data){
                let formData = {};
                formData['grant_type'] = 'password'
                formData['googleToken'] = data
                formData['restrictInactiveCounties'] = true
                this.store.login(formData,true,'basic',this)
            },
            changeTheme(){
                if(this.currentTheme=='light'){
                    this.currentTheme = 'dark'
                } else {
                    this.currentTheme = 'light'
                }
            },
            submit(){
                let formData = {};
                formData['grant_type'] = 'password'
                formData['password'] = this.form.password
                formData['username'] = this.form.username
                formData['restrictInactiveCounties'] = true
                this.store.login(formData,true,'basic',this)
            },
            validEmail(){
                let regexp =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                return regexp.test(this.form.username.toLowerCase())
            },
            switchVisibility() {
              this.showpassword = !this.showpassword;
              if (this.showpassword) {
                this.inputtype = "text";
                this.iconType = "eye";
              } else {
                this.inputtype = "password";
                this.iconType = "eye-off";
              }
            },
            UserDetail(){
                this.store.getUserDetail({},true,this,'').then(() => {
                    // if(Object.keys(response).length){
                    //     if(window.localStorage.storekey){
                    //         const store = JSON.parse(window.localStorage.storekey)
                    //         if(store.customerDetail){
                    //           store.customerDetail = this.store.userDetail
                    //           window.localStorage.setItem("storekey",  JSON.stringify(store))
                    //            if(this.static_vars.getCookie('zuluUserDetail')){
                    //               this.static_vars.delete_cookie('zuluUserDetail')
                    //             }
                    //         }
                    //     }else{
                    //         this.static_vars.setCookie('zuluUserDetail',JSON.stringify(response),'100d')
                    //     }
                    // }
                })
            },
            allCategoryPrices(){
                let formData = {
                    "from": moment().subtract(2, 'week').format('YYYY-MM-DD'),
                    "to": moment().format('YYYY-MM-DD'),
                    "limit": 10,
                    "page": 1,
                }

                let forexPayload = {
                    "categories": [
                        1
                    ],
                    ...formData
                }
                let commoditiesPayload = {
                    "categories": [
                        3
                    ],
                    ...formData
                }
                let cryptoPayload = {
                    "categories": [
                        4
                    ],
                    ...formData
                }
                let indicesPayload = {
                    "categories": [
                        7
                    ],
                    ...formData
                }
                let stocksPayload = {
                    "categories": [
                        8
                    ],
                    ...formData
                }
                this.store.getAllCurrencyCategories(forexPayload,false)
                this.store.getAllCurrencyCategories(commoditiesPayload,false)
                this.store.getAllCurrencyCategories(cryptoPayload,false)
                this.store.getAllCurrencyCategories(indicesPayload,false)
                this.store.getAllCurrencyCategories(stocksPayload,false)
            },
            callSocket(){
                PriceFeed.getInstance().deactivate();
            },
            getWatchList(){
                this.store.getWatchList({},true,'1')
                this.store.getWatchList({},true,'180')
            },
            getCustomerDetail(){
                this.store.callCustomerDetail({},false).then((response) =>{
                    if(Object.keys(response).length && response.accounts && Object.keys(response.accounts).length){
                        this.store.$patch({'dashboardTradesTab' : 'open'})
                      this.store.getProviderOpenTrades({'page': '0','size':'10'},true,this.store.userSelectedAccount.brokerAccountId);
                      this.getDashboardData(this.store.userSelectedAccount.zuluAccountId);
                      this.getCopyLeaders(this.store.userSelectedAccount.zuluAccountId);
                    }
                })
            },
            getCopyLeaders(ID){
                this.store.getCopyStats({},false,ID)
                this.store.GetCopyTraders({},false)
            },
            getDashboardData(ID){
                this.store.getUserDashboardData({},true,ID)
            },
            SetToken(response){
                if(Object.keys(response).length && response.access_token){
                    localStorage.setItem('zulutoken', response.access_token);
                    if(response.refresh_token){
                        localStorage.setItem('zulurefreshToken', response.refresh_token);
                    }
                }
            }
        },
        mounted() {
            // load(`${this.static_vars.recaptchaKey}`,{useRecaptchaNet: true}).then((recaptcha) => {
            //   recaptcha.execute('login').then((token) => {
            //       // console.log(token) // Will print the token
            //       this.captchaToken = token
            //     })
            // })
            if(localStorage.getItem('theme') === null){
                localStorage.setItem('theme', 'light');
            }
            var toggle = document.getElementById("theme-toggle");
            var storedTheme = localStorage.getItem('theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
            if (storedTheme) {
                document.documentElement.setAttribute('data-theme', storedTheme)
            }
            if(toggle){
                toggle.onclick = function() {
                    var currentTheme = document.documentElement.getAttribute("data-theme");
                    var targetTheme = "light";
                    if (currentTheme === "light") {
                        targetTheme = "dark";
                    }
                    document.documentElement.setAttribute('data-theme', targetTheme)
                    localStorage.setItem('theme', targetTheme);
                };
            }
            this.currentTheme = localStorage.getItem('theme')
        },
    }
</script>
